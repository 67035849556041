
                                @import "./src/kit/styles/config/_include";
                            
.instruction {
    margin-bottom: 5.625rem;

    @include media-breakpoint-down(lg) {
        margin-bottom: 3.75rem;
    }

    .container {
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

    &__title {
        font-size: $h3-font-size;
        line-height: $headings-line-height;
        font-weight: $font-weight-semibold;
        text-align: center;
        margin: 0 0 3.65rem;

        @include media-breakpoint-down(lg) {
            font-size: $h6-font-size;
            margin-bottom: 2.625rem;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        counter-reset: li;
        list-style: none;
        padding: 0 4.375rem;

        @include media-breakpoint-down(xxl) {
            padding: 0 3.125rem;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 1.5rem;
        }

        @include media-breakpoint-down(lg) {
            gap: 12px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                border: none;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        width: 21.8%;
        min-width: 220px;
        max-width: 280px;
        padding-top: 146px;
        background-repeat: no-repeat;
        font-size: $text-size-lg;
        line-height: $text-line-height-xl;
        font-weight: $font-weight-medium;
        color: $color-black;

        @include media-breakpoint-down(lg) {
            padding-top: 86px;
            min-width: 150px;
            max-width: 150px;
        }

        &:nth-child(1) {
            background-image: url(./img/make-registration-desktop.svg);

            @include media-breakpoint-down(lg) {
                background-image: url(./img/make-registration-mobile.svg);
            }
        }

        &:nth-child(2){
            background-image: url(./img/make-ordering-desktop.svg);

            @include media-breakpoint-down(lg) {
                background-image: url(./img/make-ordering-mobile.svg);
            }
        }

        &:nth-child(3) {
            background-image: url(./img/write-review-desktop.svg);

            @include media-breakpoint-down(lg) {
                background-image: url(./img/write-review-mobile.svg);
            }
        }

        &:nth-child(4) {
            background-image: url(./img/get-cashback-desktop.svg);

            @include media-breakpoint-down(lg) {
                background-image: url(./img/get-cashback-mobile.svg);
            }
        }

        &::before {
            display: flex;
            align-items: center;
            justify-content: center;
            content: counter(li);
            counter-increment: li;
            background: $color-black-soft;
            color: $color-white;
            height: 2.125rem;
            width: 2.125rem;
            font-size: $text-size-lg;
            line-height: $text-line-height-lg;
            border-radius: 2.125rem;
            margin-bottom: 0.375rem;

            @include media-breakpoint-down(lg) {
                height: 1.8125rem;
                width: 1.8125rem;
                font-size: $text-size-sm;
            }
        }
    }

    &__description {
        max-width: 200px;

        @include media-breakpoint-down(lg) {
            font-size: $text-size-sm;
            line-height: $text-line-height-xl;
        }
    }

    &__auth-link {
        text-decoration: underline;
        color: $color-black;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}
