
                                @import "./src/kit/styles/config/_include";
                            
.p-main {
    background: url(./img/fractals.svg) center 7.625rem / auto no-repeat;

    @include media-breakpoint-up(lg) {
        padding-top: 1.875rem;
    }
}
