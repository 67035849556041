
                                @import "./src/kit/styles/config/_include";
                            
.products {
    margin-bottom: 5.625rem;

    @include media-breakpoint-down(lg) {
        margin-bottom: 3.75rem;
    }

    &__title {
        font-size: $h2-font-size;
        line-height: $headings-line-height;
        font-weight: $font-weight-medium;
        margin: 0 0 3.75rem;

        @include media-breakpoint-down(lg) {
            font-size: $h3-font-size;
            margin-bottom: 1.25rem;
            text-align: center;
        }

        @include media-breakpoint-down(md) {
            font-size: $h6-font-size;
            padding-left: 1.5rem;
        }
    }

    &__list {
        display: grid;
        justify-content: center;
        gap: 1.25rem;
        grid-template-columns: repeat( auto-fill, 340px);

        @include list-unstyled();

        margin-bottom: 1.25rem;

        @include media-breakpoint-down(lg) {
            margin-bottom: 1.25rem;
        }
    }

    &__item {
        flex: 0 0 auto;
    }

    &__show-more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 0.75rem;
        font-size: $text-size-sm;
        line-height: $text-line-height-xl;
        font-weight: $font-weight-medium;
        background-color: #E8EDEE;
        transition: all .1s ease;
        white-space: nowrap;
        &:hover {
            background-color: #D2DADC;
        }
        // &:focus {
        //     background-color: #B5BCBD;
        // }

        @include media-breakpoint-down(lg) {
            min-width: auto;
            width: 21.4375rem;
        }

        .icon-reload {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 1.5rem;
            height: 1.5rem;
            font-size: 1.25rem;
            margin-right: 0.375rem;
        }
    }

    &--bottom {
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 60px;

            .products__title {
                margin-bottom: 91px;
            }

            .products__list {
                margin-bottom: 30px;
            }
        }
    }
}

.products__pagination {
    list-style-type: none;
    display: flex;
    align-items: center;
    &-outer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5rem;
        flex-wrap: wrap;
    }
    &-wrap {
        margin-left: auto;
    }

    @include media-breakpoint-down(xl) {
        padding: 0;
        &-outer {
            flex-direction: column;
            align-items: center;
        }
        &-wrap {
            margin: 1.5rem 0 0;
        }
    }
    @include media-breakpoint-down(lg) {
        &-wrap {
            display: none;
        }
    }
    &-item {
        height: 39px;
        min-width: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 1px solid transparent;
        font-size: .875rem;
        font-weight: $font-weight-medium;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-gray-1000 !important;
            width: 100%;
            height: 100%;
        }
        &:hover {
            border-color: #D0DBDD;
        }
        &.active {
            border-color: $color-black-soft;
            background-color: $color-black-soft;
            a {
                color: $color-white !important;
                pointer-events: none;
            }
        }
        &:not(:last-child) {
            margin-right: 6px;
        }
        &__button {
            background-color: #E8EDEE;
            &:hover {
                background-color: #D2DADC;
            }
            a {
                grid-gap: .6rem;
                padding: 0 1rem;
                color: $color-black !important;
            }
            span {
                font-size: 13px;
            }
        }
    }
}