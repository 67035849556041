
                                @import "./src/kit/styles/config/_include";
                            
.categories {
    margin-bottom: 5.625rem;

    @include media-breakpoint-down(lg) {
        margin-bottom: 3.75rem;
    }

    .container {
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

    &__wrapper {
        position: relative;
        background-color: $color-gray-600;
        padding: 4.5625rem 7.375rem 3.6875rem;
        border-radius: 0.625rem;

        @include media-breakpoint-down(lg) {
            min-height: 244px;
            padding: 1.5rem 0;
        }

        @include media-breakpoint-down(sm) {
            border-radius: 0;
        }
    }

    &__list {
        padding-right: 0;
        padding-left: 0;
        @include media-breakpoint-down(lg) {
            padding-top: 2.125rem;
            padding-bottom: 1rem;
        }
    }

    &__link {
        display: block;
        padding-right: 28px;
        @include media-breakpoint-down(xxxl) {
            padding-right: 34px;
        }
        @include media-breakpoint-down(xxl) {
            padding-right: 27px;
        }
        @include media-breakpoint-down(xl) {
            padding-right: 22px;
        }
        @include media-breakpoint-down(lg) {
            padding-right: 23px;
        }
    }

    &__image {
        display: block;
        border-radius: 50%;
        border: $border-width-sm solid $color-gray-700;
        margin-bottom: 0.75rem;
        background: $color-white;
        object-fit: contain;

        @include media-breakpoint-down(lg) {
            width: 5rem;
            height: 5rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__name {
        text-align: center;
        color: $color-black;
        padding-left: 0.8125rem;
        padding-right: 0.8125rem;
        font-size: $text-size-sm;
        line-height: $text-line-height-xl;

        @include media-breakpoint-down(lg) {
            padding: 0;
            // max-height: 36px;
            // max-width: 90px;
        }
    }

    &__nav-button {
        position: absolute;
        border: none;
        width: 3rem;
        height: 3rem;
        padding: 0.75rem;
        background-color: $color-black-soft;
        border-radius: 0.75rem;
        top: calc(50% - 1.0625rem);

        @include media-breakpoint-down(lg) {
            display: none;
        }

        &:disabled {
            background-color: $color-gray-700;
        }


        &--prev {
            left: -1.5rem;
        }

        &--next {
            right: -1.5rem;
        }

        .icon-arrow-left,
        .icon-arrow-right {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 1.5rem;
            height: 1.5rem;
            font-size: 1.25rem;
            color: $color-white;
        }
    }
}
