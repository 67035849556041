
                                @import "./src/kit/styles/config/_include";
                            
.product-card {
    position: relative;
    min-height: 35.6875rem;
    width: 21.25rem;
    background: $color-white;
    border-radius: 0.625rem;
    border: $border-width-sm solid $color-gray-500;
    padding: 1.1875rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__favorite-toggle {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1.1875rem;
        right: 1.1875rem;
        width: 1.5rem;
        height: 1.5rem;
        border: none;
        padding: 0;
        background-color: transparent;

        .icon-heart-simple {
            font-size: 1.25rem;
            color: $color-gray-900;
            transition: all .2s;
        }

        .icon-heart-fill {
            font-size: 1.25rem;
            color: $color-brand;
        }

        &:hover, &.active {
            .icon-heart-simple {
                color: $color-brand;
            }
        }
    }

    &__img {
        display: block;
        margin-bottom: 0.875rem;
        img {
            object-fit: contain;
            object-position: center;
        }
    }

    &__cashback-level {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: $text-line-height-xl;
        color: $color-white;
        margin-bottom: 0.25rem;
        font-size: .875rem;
        padding: 0 0.25rem 0 0.375rem;

        & + & {
            margin-left: .375rem;
        }

        &--high {
            border-radius: 0.625rem;
            background-color: $color-brand;
        }

        &--middle {
            width: 3.375rem;
            height: 1.5625rem;
            border-radius: 0.625rem;
            background-color: $color-orange;
        }

        .icon-fire {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 1.125rem;
            height: auto;
            margin-right: 0.25rem;
            font-size: 1rem;
        }
    }

    &__cashback-value {
        font-weight: $font-weight-bold;
        font-size: .875rem;
        line-height: 1.5;
    }

    &__price-container {
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
    }

    &__price {
        font-weight: $font-weight-semibold;
        line-height: $text-line-height-sm;

        &--actual {
            font-size: 1.875rem;
            color: $color-black;
            margin-right: 0.375rem;
        }

        &--no-actual {
            font-size: $text-size-lg;
            color: $color-gray-1000;
            text-decoration: line-through;
        }

        &--message {
            display: block;
            margin-top: 0.375rem;
            font-size: .6875rem;
            line-height: 1.2;
            color: $color-brand;
            font-weight: 500;
            letter-spacing: -0.02em;
        }
    }

    .icon-info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.125rem;
        height: 1.125rem;
        margin-right: 0.375rem;
        transition: all .2s;
        color: #C8CDCE;
        &:hover {
            color: $color-black-soft;
        }
    }

    &__title {
        line-height: 1.7;
        color: $color-gray-1200;
        margin-bottom: 0.625rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: all .2s ease;
        font-size: .875rem;
        &:hover {
            color: $color-brand;
        }
    }

    &__more {
        text-align: center;
        padding: 0.75rem 3.125rem;
        background: $color-yellow;
        font-size: 1rem;
        line-height: $text-line-height-xl;
        font-weight: $font-weight-medium;
        border: none;
        border-radius: 0.625rem;
        width: 100%;
        transition: all .2s ease;
        color: $color-black;
        &:hover {
            background-color: $color-orange;
        }
    }

    &__additionat-text {
        margin-top: 0.75rem;
        font-size: .625rem;
        line-height: .75rem;
        color: $color-gray-1200;

    }
}

.partners-list {
    display: flex;
    margin-bottom: .875rem;
    list-style: none;
    overflow: hidden;
    margin-top: auto;

    &__item {
        margin-right: 0.625rem;
    }

    &__link {
        display: block;
        color: $color-gray-900;
        padding: 0.1875rem 0.4375rem;
        border: $border-width-sm solid $color-gray-700;
        border-radius: 0.75rem;
        font-size: $text-size-sm;
        line-height: $text-line-height-xl;
        font-weight: $font-weight-medium;
        transition: all .2s ease;
        &:hover {
            color: $color-brand;
        }
    }
}
