
                                @import "./src/kit/styles/config/_include";
                            
.banner {
    margin-bottom: 5.625rem;

    @include media-breakpoint-down(lg) {
        margin-bottom: 3.75rem;
    }

    .container {
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4.0625rem 6.5625rem;
        background: url(./img/banner-image.svg) center / cover no-repeat;
        background-color: $color-yellow;

        @include media-breakpoint-down(lg) {
            padding: 4.3125rem 1.5rem 2.45rem;
            background-image: none;
            border-radius: 1.875rem;
        }
    }

    &__title {
        max-width: 43.75rem;
        font-weight: $font-weight-semibold;
        font-size: $h1-font-size;
        line-height: $headings-line-height;
        text-align: center;
        margin: 0 0 1.875rem;

        @include media-breakpoint-down(xl) {
            font-size: $h2-font-size;
            max-width: 35rem;
            margin-bottom: 1.5rem;
        }

        @include media-breakpoint-down(lg) {
            font-size: 2.625rem;
        }
    }

    &__buy-with-casback {
        display: flex;
        align-items: center;
        padding: 0.625rem 1.375rem;
        font-weight: $font-weight-medium;
        border: $border-width-lg solid rgba($color-black, 0.3);
        border-radius: 0.3125rem;
        background: transparent;
        margin-bottom: 3.75rem;

        @include media-breakpoint-down(lg) {
            font-size: 0.75rem;
            padding: 1.0625rem 1.4375rem;
            margin-bottom: 2.5625rem;
            border: $border-width-sm solid rgba($color-black, 0.1);
            border-radius: 0.75rem;
        }

        .icon-arrow-right {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 1.5rem;
            height: 1.5rem;
            font-size: 1.25rem;
            color: $color-black-soft;
            margin-left: 0.375rem;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    &__promo-list {
        display: flex;
        list-style: none;
        gap: 1rem;
        margin-bottom: 1.875rem;

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            gap: 0.375rem;
            justify-content: center;
            margin-bottom: 1.75rem;
        }
    }

    &__promo-link {
        display: block;
    }

    &__promo-image {
        border-radius: 0.75rem;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    &__description {
        display: flex;
        align-items: center;
        padding: 0.5625rem 0.625rem;

        @include media-breakpoint-down(lg) {
            font-size: $text-size-xs;
            line-height: $text-line-height-md;
            opacity: 0.6;
            padding: 0.4375rem 2.5rem;
        }

        .icon-currency-circle-rub {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.0625rem;
            height: auto;
            font-size: 1.625rem;
            margin-right: 0.625rem;

            @include media-breakpoint-down(lg) {
                margin-right: 0.9375rem;
            }
        }
    }
}
